import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialogActions, MatDialogContent, MatDialogTitle } from '@angular/material/dialog'

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions],
})
export class PopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
